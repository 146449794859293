import { museumProgrammeConstants } from '../constants';
import { museumProgrammeService } from '../services';
import { alertActions } from './';
export const museumProgrammeAction = {
    getMuseumProgrammeMasterData,
	getProgrammePreview,
	putProgrammeSubmited,
	getProgrammeListing,
	putProgrammeApproveReject,
	deleteProgramme,
	submitProgrammeDetails,
	getProgrammeDetails,
	getSearchProgramme,
	postFilterProgramme,
	getCardProgramme,
	getProgrammeRecommendations,
	postExistingProgramme
}
function getMuseumProgrammeMasterData(data){
    return dispatch => {
		dispatch(request({ data }));
		museumProgrammeService.getMuseumProgrammeMasterData(data).then(
			data => {
				dispatch(success(data));

			},
			error => {
				dispatch(failure(error.Message));

			}
		);
	};

	function request(data) {
		return { type: museumProgrammeConstants.GET_PROGRAMME_MASTER_DATA_REQUEST, data };
	}

	function success(data) {
		return { type: museumProgrammeConstants.GET_PROGRAMME_MASTER_DATA_SUCCESS, data };
	}

	function failure(data) {
		return { type: museumProgrammeConstants.GET_PROGRAMME_MASTER_DATA_FAILURE, data };
	}
}

function getProgrammeListing(data){
    return dispatch => {
		dispatch(request({ data }));
		museumProgrammeService.getProgrammeListing(data).then(
			data => {
				dispatch(success(data));

			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: museumProgrammeConstants.GET_PROGRAMME_REQUEST, data };
	}

	function success(data) {
		return { type: museumProgrammeConstants.GET_PROGRAMME_SUCCESS, data };
	}

	function failure(data) {
		return { type: museumProgrammeConstants.GET_PROGRAMME_FAILURE, data };
	}
}

function getProgrammePreview(data){
    return dispatch => {
		dispatch(request({ data }));
		museumProgrammeService.getProgrammePreview(data).then(
			data => {
                dispatch(success(data));
			},
			error => {
                dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: museumProgrammeConstants.GET_PROGRAMME_PREVIEW_REQUEST, data };
	}

	function success(data) {
		return { type: museumProgrammeConstants.GET_PROGRAMME_PREVIEW_SUCCESS, data };
	}

	function failure(data) {
		return { type: museumProgrammeConstants.GET_PROGRAMME_PREVIEW_FAILURE, data };
	}
}

function putProgrammeSubmited(data){
    return dispatch => {
		dispatch(request({ data }));
		museumProgrammeService.putProgrammeSubmited(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: museumProgrammeConstants.PUT_PROGRAMME_DETAILS_SUBMITED_REQUEST, data };
	}

	function success(data) {
		return { type: museumProgrammeConstants.PUT_PROGRAMME_DETAILS_SUBMITED_SUCCESS, data };
	}

	function failure(data) {
		return { type: museumProgrammeConstants.PUT_PROGRAMME_DETAILS_SUBMITED_FAILURE, data };
	}
}

function putProgrammeApproveReject(data){
    return dispatch => {
		dispatch(request({ data }));
		museumProgrammeService.putProgrammeApproveReject(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));

			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));

			}
		);
	};

	function request(data) {
		return { type: museumProgrammeConstants.PUT_PROGRAMME_APPROVE_REJECT_REQUEST, data };
	}

	function success(data) {
		return { type: museumProgrammeConstants.PUT_PROGRAMME_APPROVE_REJECT_SUCCESS, data };
	}

	function failure(data) {
		return { type: museumProgrammeConstants.PUT_PROGRAMME_APPROVE_REJECT_FAILURE, data };
	}
}

function deleteProgramme(data){
    return dispatch => {
		dispatch(request({ data }));
		museumProgrammeService.deleteProgramme(data).then(
			data => {
				dispatch(success(data));
				dispatch(alertActions.success(data.Message,true));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: museumProgrammeConstants.DELETE_PROGRAMME_REQUEST, data };
	}

	function success(data) {
		return { type: museumProgrammeConstants.DELETE_PROGRAMME_SUCCESS, data };
	}

	function failure(data) {
		return { type: museumProgrammeConstants.DELETE_PROGRAMME_FAILURE, data };
	}
}

function submitProgrammeDetails(programmedata){
    return dispatch => {
		dispatch(request({ programmedata }));
		museumProgrammeService.submitProgrammeDetails(programmedata).then(
			data => {
				const fetchProgrammeId = programmedata.ProgrammeId?programmedata.ProgrammeId:data.ProgrammeId;
				dispatch(success(data));
				dispatch(getProgrammeDetails(fetchProgrammeId));

				if(programmedata.step === 3)
					dispatch(alertActions.success(data.Message,true));

			},
			error => {
				dispatch(failure(error.Message));
				// if(programmedata.step === 3)
					dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));

			}
		);
	};

	function request(data) {
		return { type: museumProgrammeConstants.SUBMIT_PROGRAMME_DETAILS_REQUEST, data };
	}

	function success(data) {
		return { type: museumProgrammeConstants.SUBMIT_PROGRAMME_DETAILS_SUCCESS, data };
	}

	function failure(data) {
		return { type: museumProgrammeConstants.SUBMIT_PROGRAMME_DETAILS_FAILURE, data };
	}
}



function getProgrammeDetails(data,i){

    return dispatch => {
		dispatch(request({ data }));
		if(!i){
		museumProgrammeService.getProgrammeDetails(data).then(
			data => {
				dispatch(success(data));

			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));

			}
		);}
	};

	function request(data) {
		return { type: museumProgrammeConstants.GET_PROGRAMME_DETAILS_REQUEST, data };
	}

	function success(data) {
		return { type: museumProgrammeConstants.GET_PROGRAMME_DETAILS_SUCCESS, data };
	}

	function failure(data) {
		return { type: museumProgrammeConstants.GET_PROGRAMME_DETAILS_FAILURE, data };
	}
}

function getSearchProgramme(data){
    return dispatch => {
		dispatch(request({ data }));
		museumProgrammeService.getSearchProgramme(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
			}
		);
	};

	function request(data) {
		return { type: museumProgrammeConstants.GET_SEARCH_PROGRAMME_MASTER_DATA_REQUEST, data };
	}

	function success(data) {
		return { type: museumProgrammeConstants.GET_SEARCH_PROGRAMME_MASTER_DATA_SUCCESS, data };
	}

	function failure(data) {
		return { type: museumProgrammeConstants.GET_SEARCH_PROGRAMME_MASTER_DATA_FAILURE, data };
	}
}


function postFilterProgramme(data,i,selectedData,PrevResponse){
    return dispatch => {
		if(!i){
			let newData={...data}
			if(PrevResponse){
				newData.TourDate=PrevResponse.TourDate
			}
			dispatch(request(PrevResponse?newData:data));
			museumProgrammeService.postFilterProgramme(PrevResponse?newData:data).then(
			response => {
				if(response){
				response.TourDate=PrevResponse?newData.TourDate:data.TourDate
				}
				dispatch(success(response,data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
		}
		else{
			dispatch(success(data,selectedData));

		}
	};

	function request(data) {
		return { type: museumProgrammeConstants.POST_FIND_PROGRAMME_REQUEST, data};
	}

	function success(data,selectedData) {
		return {
			 type: museumProgrammeConstants.POST_FIND_PROGRAMME_SUCCESS, data  ,selectedData:selectedData};
	}

	function failure(data) {
		return { type: museumProgrammeConstants.POST_FIND_PROGRAMME_FAILURE, data };
	}
}

function getCardProgramme(data){
    return dispatch => {
		dispatch(request({ data }));
		museumProgrammeService.getCardProgramme(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));
			}
		);
	};

	function request(data) {
		return { type: museumProgrammeConstants.GET_CARD_PROGRAMME_DATA_REQUEST, data };
	}

	function success(data) {
		return { type: museumProgrammeConstants.GET_CARD_PROGRAMME_DATA_SUCCESS, data };
	}

	function failure(data) {
		return { type: museumProgrammeConstants.GET_CARD_PROGRAMME_DATA_FAILURE, data };
	}
}
function getProgrammeRecommendations(data){
    return dispatch => {
		dispatch(request({ data }));
		museumProgrammeService.getProgrammeRecommendations(data).then(
			data => {
				dispatch(success(data));

			},
			error => {
				dispatch(failure(error.Message));

			}
		);
	};

	function request(data) {
		return { type: museumProgrammeConstants.GET_PROGRAMME_RECOMMENDATIONS_REQUEST, data };
	}

	function success(data) {
		return { type: museumProgrammeConstants.GET_PROGRAMME_RECOMMENDATIONS_SUCCESS, data };
	}

	function failure(data) {
		return { type: museumProgrammeConstants.GET_PROGRAMME_RECOMMENDATIONS_FAILURE, data };
	}
}

function postExistingProgramme(data,i){
    return dispatch => {
		if(data)
		dispatch(request({ data }))
		else
		dispatch(failure(null))

		if(!i){
		museumProgrammeService.postExistingProgramme(data).then(
			data => {
				dispatch(success(data));
			},
			error => {
				dispatch(failure(error.Message));
				dispatch(alertActions.error(error.Message?error.Message:'Something went wrong',true));

			}
		);
		}
	};

	function request(data) {
		return { type: museumProgrammeConstants.POST_EXISTING_PROGRAMME_REQUEST, data };
	}

	function success(data) {
		return { type: museumProgrammeConstants.POST_EXISTING_PROGRAMME_SUCCESS, data };
	}

	function failure(data) {
		return { type: museumProgrammeConstants.POST_EXISTING_PROGRAMME_FAILURE, data };
	}
}
